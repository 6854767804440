import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import H2 from "~/components/Typography/H2";
import LessonLayout from "~/layouts/lesson";
import ContentWrapper from "~/components/content-wrapper";
import UnorderedList from "~/components/List/Unordered";

const Page = () => {
  return (
    <LessonLayout
      current_step={7}
      lesson={"Lesson 4"}
      color={"hs"}
      total_count={15}
      module_title="What Could Happen?"
    >
      <ContentWrapper>
        <H2 underline>Step 7</H2>

        <div className="mt-5 w-2/3 flex content-end">
          <StaticImage
            alt="Hand using tablet."
            className="rounded"
            src="../../../images/lesson_4_step_7.jpg"
            loading="eager"
            placeholder="blurred"
            style={{
              filter: "drop-shadow(8px 8px 8px rgba(0, 0, 0, 0.25))"
            }}
          />
        </div>

        <p>As you have seen, companies need to follow specific rules when they write their ads for a prescription medication. The FDA does more than just say what can be in an ad for a prescription medication.</p>
        <p>The FDA helps make sure that the medications are safe and effective. The following points give information about what the FDA does.</p>

        <UnorderedList>
          <li>New prescription medicines must be approved by the FDA before they can be sold in the United States.</li>
          <li>The FDA does <em>not</em> develop or test products before approving them. Experts at the FDA review the results of clinical testing done by the drug manufacturers. If the drug is approved, the FDA has determined that the benefits are greater than the known risks.</li>
        </UnorderedList>
      </ContentWrapper>
    </LessonLayout>
  )
}
export default Page;

